.outfit-page {
  display: flex;
  justify-content: space-between;
  padding: 110px 20px 50px 20px;
}

.outfit-ad {
  width: 20vw;
  height: 80vh;
}

.outfit-slider {
  width: 30vw;
  height: 80vh;
}

.outfit-details {
  width: 40vw;
  height: 80vh;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.outfit-price {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: bold;
  text-align: center;
}

.outfit-divider {
  width: 100%;
  border: 1px solid black;
}

.outfit-topdown{
  display: flex;
  justify-content: space-between;
}

.outfit-topdown h4 {
  align-self: flex-start;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 500;
}

.outfit-link {
  color: blue;
  text-decoration: underline;
  margin: 20px 0;
  align-self: flex-start;
}

.outfit-warning{
  font-size: 0.7rem;
  text-align: left;
  margin-top: 40px;
}

.slick-slider {
  height: 100%; /* Ensures the slider takes the full height */
}

.slick-list {
  height: 100%; /* Ensures the slider list takes the full height */
}

.slick-slide {
  display: flex !important; /* Ensures slides are flex containers */
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensures slides take the full height */
}

.slick-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* Override default styles for the slick carousel arrows */
.slick-prev, .slick-next {
  color: black !important; /* Ensures the arrow buttons are black */
  z-index: 1; /* Ensures the arrows are above the content */
}

.slick-prev::before, .slick-next::before {
  color: black !important; /* Ensures the arrow icons are black */
  font-size: 2em; /* Optional: Increase the size of the arrow */
}

/* Optional: You can also customize the hover state if needed */
.slick-prev:hover, .slick-next:hover {
  color: black !important; /* Ensures the color remains black on hover */
}

.slick-prev:hover::before, .slick-next:hover::before {
  color: black !important; /* Ensures the icon color remains black on hover */
}

@media (max-width: 1024px) {
  .outfit-page{
    flex-direction: column;
    gap: 50px;
  }
  .outfit-ad{
    width: 100%;
    height: 20vh;
    order: 2;
  }
  .outfit-slider{
    width: 100%;
    height: 60vh;
    order: 1;
  }
  .outfit-details{
    width: 100%;
    height: 50vh;
    order: 3;
  }
  .slick-prev,.slick-next{
    display: none !important;
  }
}
