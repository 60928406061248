.not-found-container {
  text-align: center;
  padding: 50px;
}

.not-found-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.not-found-container a {
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
}

.not-found-container a:hover {
  text-decoration: underline;
}
