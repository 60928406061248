.home {
  padding-top: 75px;
  padding-bottom: 50px;
}

.home span {
  display:block;
  text-align: center;
  font-size: 3.5rem;
  font-weight: 400;
  padding-bottom: 40px;
}

.home-categories {
  display: flex;
  margin: 10px 40px;
}

.category-box {
  width: 18%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  cursor: pointer;
  margin: 0px 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
}

.category-box:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

.category-name {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 1.2em;
  text-decoration: none;
}
.category-box:hover .category-name {
  text-decoration: none; /* Ensure no underline on hover */
}

@media (max-width: 1024px) {
  .category-box {
    width: 45%; /* Two boxes per row */
    margin: 18px 18px; /* Adjust margins for better spacing */
    transform: none;
  }
  .category-box:hover{
    transform: none;
  }

  .home-categories {
    margin: 0px 20px; /* Reduce outer margin */
    flex-wrap: wrap;
  }
}

/* Styles for mobile */
@media (max-width: 768px) {
  .category-box {
    width: 100%; /* One box per row */
    margin: 10px 0; /* Adjust margins for better spacing */
  }
  .category-box:hover{
    transform: none;
  }

  .home-categories {
    flex-direction: column; /* Ensure items stack vertically */
    margin: 0px 10px; /* Further reduce outer margin */
    flex-wrap: wrap;
  }
  .home span{
    font-size: 2rem;
    margin-bottom: 0;
  }
}