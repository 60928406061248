/* src/Category.css */

.category-page {
  padding-bottom: 50px;
  text-align: center;
  padding-top: 50px;
}

.category-page h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 500;
}

.category-items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.filter-buttons {
  display: flex;
  justify-content:flex-end;
  gap: 60px;
  margin-bottom: 20px;
  margin-right: 120px;
}

.dropdown-menu-c{
  display: none;
  position: absolute;
  top: calc(20% + 15px); /* Position dropdowns below buttons */
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  z-index: 1000;
}
.dropdown-menu-c.show{
  display: block;
}
.dropdown-menu-c div {
  padding: 10px;
  cursor: pointer;
  font-size: 0.8rem;
  white-space: nowrap;
}

.dropdown-menu-c div:hover {
  background-color: #f0f0f0;
}
.filter-text-c{
  cursor: pointer;
  size: 0.9rem;
}
.dropdown-overlay-c{
  position: relative;
}

@media (max-width: 768px) {
  .category-page h2{
    font-size: 1.5rem;
  }
  .category-items{
    gap: 15px;
  }
  .filter-buttons{
    gap: 15vw;
    margin-right: 29vw;
  }
}