/* src/Footer.css */

.footer {
  background-color: #333;
  color: #fff;
  padding: 1px;
  text-align: center;
}

.footer h1 {
  font-weight: 500;
  font-size: 2.5rem;
}

.footer-social a {
  color: #fff;
  margin: 0px 15px;
}
.footer-social svg{
  width: 30px;
  height: 30px;
}

.footer-copyright {
  margin-top: 25px;
  font-size: 0.8rem;
}
