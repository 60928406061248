.navbar {
  position: absolute;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background: rgba(255, 255, 255, 0);
  color: black;
  z-index: 100;
}

.navbar-logo{
  margin-top: 5px;
}

.navbar-links {
  display: flex;
  padding-right: 50px;
  margin-top: 10px;
  gap: 100px;
  align-self: flex-start;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.navbar-category{
cursor: pointer;
font-size: 1.5rem;
}
.navbar-category:hover {
  text-decoration: underline;
}

.navbar-dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 5px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  font-size: 1rem;
}

.dropdown-menu a:hover {
  background-color: #f5f5f5;
}

@media (max-width: 1024px) {
  .navbar-links{
    padding-right: 0;
  }
}

/* Styles for mobile */
@media (max-width: 768px) {
  .navbar{
    padding: 0.5rem 0.5rem;
  }
  .navbar-logo img{
    height: 50px;
    width: 57px;
  }
  .navbar-links{
    gap: 7vw;
    align-self: flex-start;
  }
  .navbar-links a{
    font-size: 1.0rem;
  }
  .navbar-category{
    font-size: 1.0rem;
  }
  .dropdown-menu a{
    white-space:unset;
  }
}