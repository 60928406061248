.about-page {
  padding-top: 100px;
  max-width: 800px;
  margin: 0 auto;
}

.about-page section {
  margin-bottom: 40px;
}

.about-page h2 {
  font-size: 30px;
  font-weight: 450;
}

.about-page p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.about-page hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin-top: 40px;
}

.about-us,.privacy-policy,.contact-us {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.about-us p:last-child,.privacy-policy-paragraph:last-child,.contact-us p:last-child {
  margin-bottom: 0
}
