.category-box-c {
  width: 28%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.category-box-pics-c{
  display: flex;
  height: 60vh;
}
.category-box-image-c {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.category-box-price-c {
  width: 100%;
  text-align:center;
  padding: 5px 0;
  background-color: #f8f8f8;
  font-weight: 300;
  border-top: 1px solid #ddd;
}

@media (max-width: 1024px) {
  .category-box-c{
    width: 45%;
  }
  .category-box-pics-c{
  height: 50vh;
  }
}
@media (max-width: 768px) {
  .category-box-c{
    width: 46%;
  }
  .category-box-pics-c{
  height: 25vh;
  }
}
